const PrivacyPolicy = () => {
  return (
    <div className='bg-gray-100 pb-10'>
      {/* Bannière */}
      <div className='bg-[#003f97] text-white py-16'>
        <div className='max-w-4xl mx-auto text-center'>
          <h1 className='text-4xl font-bold'>Politique de Confidentialité</h1>
          <p className='mt-4 text-lg'>Dernière mise à jour : 07/10/2024</p>
        </div>
      </div>

      {/* Contenu */}
      <div className='max-w-4xl mx-auto bg-white shadow-md p-8 rounded-md mt-8'>
        <section className='mb-8'>
          <h2 className='text-2xl font-bold mb-4'>
            1. Informations que Nous Collectons
          </h2>
          <p className='text-gray-700 mb-4'>
            Nous pouvons collecter plusieurs types d’informations auprès de
            vous, y compris, mais sans s’y limiter :
          </p>
          <h3 className='text-xl font-semibold mb-2'>
            a. Informations Personnelles
          </h3>
          <ul className='list-disc list-inside mb-4'>
            <li>Nom, Prénom</li>
            <li>Adresse e-mail</li>
            <li>Adresse postale</li>
            <li>Numéro de téléphone</li>
            <li>Données professionnelles</li>
          </ul>
          <h3 className='text-xl font-semibold mb-2'>
            b. Informations Démographiques et de Santé
          </h3>
          <p className='text-gray-700 mb-4'>
            Nous collectons également des informations spécifiques liées à
            l’âge, au genre, et aux données de santé non-identifiantes.
          </p>
          <h3 className='text-xl font-semibold mb-2'>
            c. Collecte pour Interviews et Études
          </h3>
          <p className='text-gray-700 mb-4'>
            Nous recueillons également des informations via des interviews
            d'experts avec consentement préalable.
          </p>
          <h3 className='text-xl font-semibold mb-2'>
            d. Informations Techniques
          </h3>
          <p className='text-gray-700 mb-4'>
            Informations concernant l’utilisation de notre plateforme, telles
            que l’adresse IP, le type de navigateur, etc.
          </p>
        </section>

        <section className='mb-8'>
          <h2 className='text-2xl font-bold mb-4'>
            2. Utilisation des Informations Collectées
          </h2>
          <p className='text-gray-700 mb-4'>
            Nous utilisons les informations pour fournir nos services,
            personnaliser l’expérience utilisateur, et pour des fins de
            recherche.
          </p>
        </section>

        <section className='mb-8'>
          <h2 className='text-2xl font-bold mb-4'>
            3. Partage des Informations
          </h2>
          <p className='text-gray-700 mb-4'>
            Nous ne vendons pas vos informations personnelles à des tiers. Nous
            partageons uniquement les données dans des cas spécifiques avec des
            partenaires ou des autorités.
          </p>
        </section>

        <section className='mb-8'>
          <h2 className='text-2xl font-bold mb-4'>
            4. Durée de Conservation des Données
          </h2>
          <p className='text-gray-700 mb-4'>
            Nous conserverons vos données aussi longtemps que nécessaire pour
            atteindre les objectifs décrits dans cette politique.
          </p>
        </section>

        <section className='mb-8'>
          <h2 className='text-2xl font-bold mb-4'>5. Sécurité des Données</h2>
          <p className='text-gray-700 mb-4'>
            Nous mettons en place des mesures de sécurité techniques et
            organisationnelles pour protéger vos données.
          </p>
        </section>

        <section className='mb-8'>
          <h2 className='text-2xl font-bold mb-4'>
            6. Droits de l’Utilisateur
          </h2>
          <p className='text-gray-700 mb-4'>
            Vous avez le droit de demander l’accès, la rectification, la
            suppression ou la portabilité de vos données personnelles.
          </p>
        </section>

        <section className='mb-8'>
          <h2 className='text-2xl font-bold mb-4'>
            7. Utilisation des Cookies
          </h2>
          <p className='text-gray-700 mb-4'>
            Nous utilisons des cookies pour améliorer l’expérience utilisateur
            sur notre plateforme. Vous pouvez configurer votre navigateur pour
            bloquer ou supprimer les cookies.
          </p>
        </section>

        <section className='mb-8'>
          <h2 className='text-2xl font-bold mb-4'>
            8. Modifications de la Politique de Confidentialité
          </h2>
          <p className='text-gray-700 mb-4'>
            Nous nous réservons le droit de modifier cette politique de
            confidentialité et publierons toute modification sur cette page.
          </p>
        </section>

        <section className='mb-8'>
          <h2 className='text-2xl font-bold mb-4'>9. Contact</h2>
          <p className='text-gray-700 mb-4'>
            Pour toute question concernant cette politique de confidentialité,
            vous pouvez nous contacter à l'adresse suivante :
          </p>
          <p className='text-gray-700'>
            <strong>CEISNA</strong> - Comité d'Experts Internationaux en Santé
            Numérique pour l'Afrique
            <br />
            Adresse postale : 61 Rue de Lyon, 75012 Paris
            <br />
            E-mail :
            <a
              href='mailto:contact@ceisna.org'
              className='text-blue-500 underline'
            >
              contact@ceisna.org
            </a>
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
