import { useState } from 'react';
import NewsCard from './NewsCard';

const News = ({ news }) => {
  const [newsLength, setNewsLength] = useState(6);

  if (news.length === 0) {
    return (
      <div className='h-[16vh] flex items-center justify-center w-[95%] md:w-[80%] mx-auto'>
        <h3 className=' text-[2rem] text-[#474747]'>
          Aucune actualité à afficher
        </h3>
      </div>
    );
  }

  const newsLoading = news.slice(0, newsLength);
  const isAllLoaded = newsLength >= news.length;

  return (
    <div className='py-10'>
      <div className='w-[95%] md:w-[80%] mx-auto'>
        <ul className='gap-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
          {newsLoading.map((newsItem, index) => (
            <NewsCard key={index} newsItem={newsItem} index={index} />
          ))}
        </ul>
        <div className='mt-4 flex items-center justify-center'>
          <button
            onClick={() => setNewsLength(newsLength + 6)}
            className={`border-[1px] px-6 py-2 rounded-[50px] bg-transparent border-[#00075e] text-[#00075e] ${
              isAllLoaded ? 'cursor-not-allowed opacity-50 ' : ''
            }`}
            disabled={isAllLoaded}
          >
            Charger plus
          </button>
        </div>
      </div>
    </div>
  );
};

export default News;
