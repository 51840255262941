import { useParams } from 'react-router-dom';
import BookingEvent from '../componants/Events/BookingEvent';
import EventDetails from '../componants/Events/EventDetails';
import EventHeaderDetails from '../componants/Events/EventHeaderDetails';
import { useDocument } from '../hooks/useDocument';

const Eventsdetails = () => {
  let { id } = useParams();

  const { docResponse } = useDocument('events', id);
  const { document } = docResponse;

  return (
    <main className=''>
      <EventHeaderDetails document={document} />
      <BookingEvent document={document} />
      {/* <EventDetails /> */}
    </main>
  );
};

export default Eventsdetails;
