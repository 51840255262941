import React from 'react';
import { Link } from 'react-router-dom';

const FooterSubCategories = () => {
  return (
    <div className='bg-[#003f97] hidden  sm:block pt-8 pb-[10rem] text-white border-b-2 border-[#ffffff50]'>
      <div className=' grid grid-cols-5 gap-3 w-[95%] mx-auto'>
        <ul className=' flex flex-col space-y-2 '>
          <li>
            <Link className='footer__link' to='/rapport-evenment'>
              Compte-rendus des événements passés
            </Link>
          </li>
          <li>
            <Link className='footer__link' to='/politique-de-confidentialite'>
              Politique de confidentialité
            </Link>
          </li>
        </ul>
        {/* <ul className=' flex flex-col space-y-2 '>
          <li>
            <Link to='/' className='footer__link'>
              Historique et objectifs du comité
            </Link>
          </li>
          <li>
            <Link className='footer__link' to='/'>
              Gouvernance et structure organisationnelle
            </Link>
          </li>
          <li>
            <Link className='footer__link' to='/'>
              Membres du comité et leurs domaines d'expertise
            </Link>
          </li>
        </ul> */}
      </div>
    </div>
  );
};

export default FooterSubCategories;
